import {mapMutations, mapActions, mapGetters} from "vuex";

//sections
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {dynamicHead} from "../../mixins/dynamic-head";

export default {
    name: "about",
    components: {
        VueSlickCarousel
    },
    mixins: [dynamicHead],
    data() {
        return {
            isPlayVideo: false,
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'articles', title: this.$t('aboutCompany.title'), slug: 'articles'}
            ],
            bigSlider: {
                "centerMode": true,
                "centerPadding": "300px",
                "focusOnSelect": true,
                "infinite": true,
                "slidesToShow": 1,
                "speed": 500,
                arrows: false,
            },
        }
    },

    mounted() {
    },
    created() {
        this.getPageContent('about-company').then(() => {
            this.setPageItem(this.pageContent)
            document.title = `${this.pageContent.title}`
        })
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getArticles: 'articles/GET_ARTICLES',
            getPageContent: 'setting/GET_PAGE_CONTENT'
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        playVideo() {
            this.isPlayVideo = !this.isPlayVideo
        },
        showNext() {
            this.$refs.aboutCarousel.next()
        },
        showPrev() {
            this.$refs.aboutCarousel.prev()
        },
    }
}
